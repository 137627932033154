import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'
const { Breadcrumbs } = Sentry.Integrations

import { Integrations as TracingIntegrations } from '@sentry/tracing'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002Fb04506815d1e456b9f87d5681cf28337@o277235.ingest.sentry.io\u002F5113846",
    environment:"production",
    publishRelease:true,
    release:"najada-nuxt@1.2.375",
    normalizeDepth:6,
    ignoreErrors:[new RegExp("Navigation aborted from.*", ""),new RegExp("Navigating to current location.*", ""),new RegExp("Redirected when going from.*", ""),new RegExp("Redirected from.*", ""),new RegExp("Avoided redundant navigation.*", "")],
    tracesSampleRate:0.01
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe({}),
    new ExtraErrorData({depth:6}),
    new ReportingObserver({}),
    new RewriteFrames({}),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":true,"tracing":true,"tracingOptions":{"hooks":["create","mount","update"],"timeout":5000,"trackComponents":true}}}),
    new Breadcrumbs({console:true,dom:true,fetch:true,history:true,xhr:true})
  ]

    config.integrations.push(new TracingIntegrations.BrowserTracing({}))

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
